@import '../common/style/mixin';

.view-app-wrapper {
	position: relative;

	ul.table-list.home-table-list {
		> li {
			.column {
				&.app-id {
					display: none !important;
				}
			}
		}
	}
}
