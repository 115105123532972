.end-user-id-search{
  max-width: 500px;

  .search-bar {
    display: flex;

    button {
      width: 100%;
      margin-left: 20px;
      font-size: 16px;
      font-weight: 400;
      background-color: #0075d2;
      line-height: 19.2px;
    }

    input.error {
      border: 1px solid red;
    }
  }

  div.error {
    color: #a92424;
    margin: 5px 0 10px 0;
  }
}