@import './theme';
@import './mixin';

.tab-wrapper {
  padding: 25px 0;

  &.-light-bg {
    .tab-list > li  {
      &:focus {
        outline: none;
      }
      .tab-btn {
        &, &:hover, &.-active {
          background-color: #fefefe;          
        }

        &:after {
          background-color: #fefefe;
        }
        &:focus {
          outline: none;
        }
      }
    }
    
    .tab-content {
      background-color: #fefefe;
    }
  }

  .tab-list {
    position: relative;
    z-index: 5;

    > li  {
      display: inline-block;
      margin: 0 -1px 0 0;
      .tab-btn {
        color: #000;
        border-radius: 0;
        padding: 15px 25px;
        font-weight: 700;
        position: relative;

        &:focus {
          outline: none !important;
        }

        &:after {
          content: '';
          background: $white none repeat 0 0;
          height: 6px;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          margin: -3px 0 0 0;
          display: none;
        }

        @include opacity(.7);

        &:hover, &.-active {
          @include opacity(1);
          color: inherit;
        }

        &.-active {
          &:after {
            display: block;
          }
        }
      }
    }
  }

  .tab-content {
    border: 1px solid $color-border;
    margin: -1px 0 0 0;
    min-height: 50px;
    padding: 15px;
    background: $white none repeat 0 0;
  }
}