.blacklist-wrapper {
  .event-type-icon {
    width: 14px;
    height: 14px;
    display: inline-block;
    margin-right: 5px;

    &.blacklist {
      background: url('/img/close-r.svg')
      
    }

    &.unblacklist {
      background: url('/img/checked-g.svg')
    }

    &.unknown {
      display: none;
    }
  }
}