@import './mixin';
.spinner,
.loading,
.loader {
  &,
  &:after {
    border-radius: 50%;
    width: 160px;
    height: 160px;
  }

  &.-small {
    &,
    &:after {
      width: 50px;
      height: 50px;
      border-width: 7px;
      margin: 10px auto;
    }
    + .loading-text {
      margin: -5px 0 5px 0;
    }
  }

  margin: 60px auto;
  font-size: 1px;
  position: relative;
  text-indent: -10000px;
  border-width: 15px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) $color-profile;

  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spinner 1.1s infinite linear;
  animation: spinner 1.1s infinite linear;

  + .loading-text {
    text-align: center;
    font-style: italic;
    margin: -45px 0 45px 0;
    @include opacity(0.7);
  }
}

.-loading {
  position: relative;
  &:after,
  &:before {
    content: '';
    position: absolute;
    z-index: 30;
  }

  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2) none repeat 0 0;
    background: transparent none repeat 0 0;
  }

  &:after {
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -50px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border-width: 12px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) $color-profile;

    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spinner 1.1s infinite linear;
    animation: spinner 1.1s infinite linear;
  }
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
