@import './theme';
@import './mixin';


/******************************************************************************
  fieldset
******************************************************************************/
fieldset {
  border: 0 none;
  padding: 0;
  margin: 0;

  legend  {
    display: block;
    margin: 0;
  }
}


/******************************************************************************
  autocomplete
******************************************************************************/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

/******************************************************************************
  input-contet
******************************************************************************/
.input-content {
  text-align: left;
  padding: 0 0 20px 0;
  position: relative;
  margin: 0;
  clear: both;

  &.-inline-textfield {
    padding-bottom: 10px;
    
    > .input-label {
      position: absolute;
      width: 60px;
      left: 5px;
      top: 5px;

      &:after {
        content: ':';
      }

      @include ellipsis();
      + input {
        padding-left: 65px;
      }
    }
  }

  > .input-content {
    padding-bottom: 0;
  }

  .as-label, label, .input-label {
    font-weight: 500;
    padding: 0 0 5px 0;
    display: block;
  }

  .input-error-message {
    margin: 5px 0 0 0;
    background: transparent none repeat 0 0;
  }

  fieldset {
    .input-radio, .input-checkbox {
      + label {
        font-weight: normal;
      }
    }
  }

  @media screen and (max-width: $layout-breakpoint-xxs) {
    padding-bottom: 15px;
  }
}

/******************************************************************************
  field 
******************************************************************************/
.textarea, .textfield {
  border-radius: $border-round;
  background: $white none repeat 0 0;
  display: block;
  padding: 10px 15px;
  border: 1px solid $color-border;
  width: 100%;

  &:hover {
    border-color: $blue;
  }

  &:focus, &:active {
    border-color: $blue;
    box-shadow: inset 0 0 2px $color-focus;
    outline: none;

    + .input-field-description {
      margin-top: -12px;
    }
  }

  &.-invalid {
    &, &:hover, &:focus, &:active {
      border-color: $color-error;
    }
  }

  &:disabled {
    background-color: #999;
  }  
}

.textfield {
  &.-small {
    padding: 5px 8px;    
  }
}

/******************************************************************************
  textarea 
******************************************************************************/
.textarea {
  height: 80px;
}

/******************************************************************************
  checkbox & radio 
******************************************************************************/
.input-radio, .input-checkbox {
  opacity: .01;
  filter: alpha(opacity=1);
  position: relative;
  z-index: -1;
  margin: 0 -15px -30px 0;

  &.-hide {
    + label {
      display: none;
    }
  }

  &.-invalid {    
    + label {
      &, &:hover {
        &:before {
          border-color: $color-error;
        }
      }
    }
  }

  &:focus {
    + label {
      &:after {
        display: block;
      }
    }
  }

  &:disabled {
    + label {
      @include opacity(.5);
      cursor: default;
    }
  }  


  &.-box {
    + label {
      padding: 10px 15px 10px 40px;
      border: 1px solid $color-border;
      border-radius: $border-round;
      background: $white none repeat 0 0;
    
      &:before, &:after {
        top: 6px;
        left: 5px;
      }
    }

    &:checked + label {
      border-color: $color-secondary;
      background-color: $color-secondary; 
      color: $white;
    }

    &.-full-width {
      position: absolute;
      left: 0;
      top: 0;

      + label {
        max-width: none;
        width: 100%;
      }
    }

    &.-shy {
      + label {
        @include opacity(.6);
        &:hover, &:focus {
          @include opacity(1);
        }
      }

      &:checked + label {
        @include opacity(1);
      }      
    }

    &.-white {
      + label {
        color: $color-font;
      }

      &:checked + label {
        border-color: $color-border;
        background-color: $white; 
        color: $color-font;
      }
    }
  }

  &.-small {
    + label {
      &:before, &:after {
        width: 13px;
        height: 13px;
        margin-top: 0;
      }

      &:after {
        margin-left: 3px;
      }
    }
  }
  
  + label {
    max-width: $max-width-small;
    display: inline-block;
    position: relative;
    white-space: normal;
    margin: 0 5px 5px 0;
    padding: 12px 10px 10px 33px;

    margin: 0 5px 5px 0;
    padding: 4px 10px 2px 30px;

    &.normal {
      font-weight: normal;
    }

    &:before, &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
    }

    &:before {
      height: 20px;
      width: 20px;
      margin: 2px 0 0 3px;
      background: $white none no-repeat center center / 13px auto;
      border: 2px solid $color-border;
    }

    &:after {
      height: 25px;
      width: 25px;      
      margin: 1px 0 0 0;      
      border: 1px dotted $color-border;
      display: none;
    }

    &:hover {
      &:before {
        border-color: $color-secondary;
      }
    }

    .text-detail {
      display: block;
      white-space: normal;
      @include opacity(.8);
    }
  }

  @media screen and (max-width: $layout-breakpoint-xxs) {
    &.-box {
      + label {
      }
    }    
  }
}

.input-radio {
  &.-small {
    &:checked {
      + label {
        &:before {
          border-width: 2px;
        }
      }
    }
  }

  + label {
    display: inline-block;
    cursor: pointer;
    white-space: nowrap;

    &:before {
      border-radius: 100%;
    }

    &:before, &:after {
    }
  }

  &:checked {
    + label {
      border-color: transparent;

      &:before {
        background-color: $blue; 
        border: 4px solid $white;
        box-shadow: 0 0 0 2px $color-secondary;
      }
    }
  }
}

.input-checkbox {
  &.-green {
    &:checked {
      + label {
        &:before {
          border-color: $green;
        }
      }
    }
  }

  + label {
    display: inline-block;
    border: 0 none;
    cursor: pointer;

    &:before, &:after {
    }

    &:before {
      border-radius: $border-round;
      background-size: 135% auto;
    }
  }

  &:checked {
    + label {
      &:before {
        border-color: $blue;        
        background-image: url('/img/checked.svg');
      }
    }
  }

  &.-toggler {
    + label {
      position: relative;
      background: #ccc none repeat 0 0; 
      padding: 0;
      margin: 0;
      width: 4rem;
      height: 2.1rem;
      border-radius: 2rem;
      &:before {
        background: $white none repeat 0 0;
        height: 2rem;
        width: 2rem;
        border-radius: 100%;
        border: 1px solid #999;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0 0 0 -.05rem;
        @include transition( all, .2s, ease-in-out); 
      }
    }

    &:checked {
      + label {
        background-color: $green;
        &:before {
          left: 100%;
          margin-left: -1.95rem;
          border: 1px solid #999;
          background-image: none;
        }
      }
    }
  }
}

/******************************************************************************
  select - option 
******************************************************************************/
.select-box {
  padding: 9px 32px 9px 10px;
  border: 1px solid $color-border;
  border-radius: $border-round;
  width: 100%;

  &:hover {
    border-color: $blue;
  }

  &:focus, &:active {
    border-color: $blue;
    box-shadow: 0 0 0 1px $color-focus;
    outline: none;
  }

  &::-ms-expand {
    display: none;
  }

  &.-invalid {
    border-color: $color-error;
    &:focus {
      outline-color: $white;
    }
  }

  &.-small {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
