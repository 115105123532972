@import './theme';
@import './mixin';

html, body, #app {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0 auto;
  position: relative;
  background: $color-background none repeat 0 0;
  min-width: $breakpoint-xs;
}

body {
  overflow: hidden;
  overflow-y: scroll;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}


ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p, .paragraph {
  padding-bottom: 20px;

  &.-half {
    padding-bottom: 15px;
  }

  &.-top {
    padding-bottom: 0;
    padding-top: 20px;
    &.-half {
      padding-top: 15px;
    }
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
} 

span.ellipsis, a.ellipsis {
  display: inline-block;
}

a {
  text-decoration: none;
  cursor: pointer;
  
  &, &:focus, &:hover, &:visited {
    color: $color-font;
  }

  &:focus {
    outline: $color-border dotted 1px;
    outline-offset: 2px;    
  }

  &.link {
    text-decoration: underline;
    color: $color-link;
    position: relative;
    
    &:active {
      top: 2px;
    }

    &.-disabled {
      color: $color-font;
      cursor: default;
      @include opacity(.5);
    }

    &.-arrow-left,
    &.-arrow-right {
      text-decoration: none;
      white-space: nowrap;
    }

    &.-arrow-right {
      &:after{
        content: ' \2192';
      }
    }

    &.-arrow-left {
      &:before{
        content: '\2190 ';
      }
    }
  }
}

h1, .h1, h2, .h2, h3, .h3 { 
  a {
    &.-arrow-right:after {
      font-size: 15px;
    }
  }
}

img {
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  border: 0 none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  tr {
    padding: 0;
    td, th {
      padding: 5px 10px;
    }
  }
}

section, main, header, footer, article, nav, picture, figure, canvas, svg {
  display: block;
}

.error-message, .input-error-message {
  color: $color-error;
  margin: 5px 0 10px 0;
  padding: 0;
}

.summary-error {
  overflow: hidden;
  color: $color-error;
  margin: 0 0 15px 0;
  padding: 0 0 0 0;
  font-weight: 700;
}

.success-message {
  border: 2px solid $color-success;
  position: relative;
  padding: 10px 10px 10px 40px;
  text-align: left;
  font-weight: 500;
  overflow: hidden;
  border-radius: $border-round;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 30px;
    background: $color-success url('/img/checked-w.svg') no-repeat center center / 15px auto;
  }
}

.layout-outer {
  max-width: $max-width;
  margin: 0 auto;
  padding: 15px;
}

.fill {
  height: 100%;
  width: 100%;
}

.paper{
  padding: 15px;
  margin: 0 0 30px 0;
  background: $white none repeat 0 0;
  border-radius: $border-round;
  box-shadow: 0 0 0 1px $color-border;

  &.-no-shadow {
    box-shadow: none;
  }

  &.-no-padding {
    padding: 0;
  }

  &.-dark {
    background-color: #a4afb7;    
  }

  &.-no-background {
    background-color: transparent;
  }
}

.flex {
  display: flex !important;
  height: 100% !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  -webkit-box-orient: vertical !important;
  flex-direction: column !important;

  > * {
    -webkit-flex-shrink: 1 !important;
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; 

    .flex-footer {
      background: #f2f2f2 none repeat 0 0 !important;
    }
  }

  > .flex-header {
    border: 0 !important;
    font-size: 100% !important;
    font: inherit !important;
    vertical-align: baseline !important;
    margin: 0 !important;
    padding: 0 !important; 
  }

  > .flex-body {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    position: relative !important;
    height: 100%;

    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 0% !important;
    -ms-flex: 1 1 0% !important;
    flex: 1 1 0% !important;

    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;

    -webkit-box-orient: vertical !important;

    &.-overflow {
      overflow: hidden !important;
    }

    .flex-body {
    }    
  }

  > .flex-footer {
  }
}

.widget-dialog {
  border-radius: 1.2rem;
  overflow-y: hidden;

  .paper-0 {
    margin-bottom: 0;
  }

  section {
    padding: 0;
    color: #000;
    background-color: $color-background;
  }

  .dialog-confirm-button {
    white-space: nowrap;
    width: 100%;
    &.-single {

      > a, button {
        width: 100%;
        border-left: 0;
        border-right: 0;
      }
    }

    > a, button {      
      display: inline-block;
      width: 50%;
      color: $color-link;
      font-weight: 700;
      border-radius: 0;
      padding: .9rem .5rem;
      background-color: transparent;
      border-right: 0;
      &:first-child {
        border-left: 0;
      }
    }
  }
}

.widget {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 300px;
  background: $white none repeat 0 0;
  z-index: 2;
  border-radius: $border-round;
  border: 1px solid $color-border;
  box-shadow: 0 2px 1px $color-border;
}

@media screen and (max-width: $layout-breakpoint-sm) {
  .layout-outer {
    padding: 10px;
  }
}

@media screen and (max-width: $layout-breakpoint-xxs) {
  p, .paragraph {
    padding-bottom: 15px;
    &.-half {      
      padding-bottom: 10px;
    }

    &-top {
      padding-bottom: 0;
      padding-top: 15px;

      &.-half {
        padding-bottom: 0;
        padding-top: 10px;        
      }
    }
  }
}