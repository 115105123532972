.find-city-bike {
  .find-bike-wrapper {
    display: flex;
    max-width: 500px;
  
    button {
      width: 100%;
      margin-left: 20px;
      font-size: 16px;
      font-weight: 400;
      background-color: #0075d2;
      line-height: 19.2px;
    }
  }
}