@import './theme';

/******************************************************************************
 === TRANSITION ===
******************************************************************************/
@mixin transition($property, $duration, $timing, $delay: 0s) {
  -webkit-transition: $property $duration $timing $delay;
  -moz-transition: $property $duration $timing $delay;
  -o-transition: $property $duration $timing $delay;
  -ms-transition: $property $duration $timing $delay;
  transition: $property $duration $timing $delay;
}

@mixin transition-dalay($delay) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -ms-transition-delay: $delay;
  transition-delay: $delay;
}

@mixin noneTransition() {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}

/******************************************************************************
 === ANIMATION ===
******************************************************************************/
@mixin animation($property, $duration, $timing, $delay: 0s) {
  -webkit-animation: $property $duration $timing $delay;
  -moz-animation: $property $duration $timing $delay;
  -o-animation: $property $duration $timing $delay;
  -ms-animation: $property $duration $timing $delay;
  animation: $property $duration $timing $delay;
}

@mixin animation-forwards() {
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/******************************************************************************
 === OTHER ===
******************************************************************************/

@mixin opacity($property) {
  opacity: $property;
  filter: alpha(opacity= ($property * 100));
}

@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}
