@import '../common/style/theme.scss';
@import '../common/style/mixin.scss';
@import '../common/style/animation.scss';
@import '../common/style/spinner.scss';
@import '../common/style/typography.scss';
@import '../common/style/utils.scss';
@import '../common/style/input.scss';
@import '../common/style/layout.scss';
@import '../common/style/list.scss';
@import '../common/style/tab.scss';
@import '../common/style/btns.scss';

.main-wrapper {
  .rds-is-vishidden {
    display: none;
  }

  padding-bottom: 20px;
  .main-header {
    background: $white none repeat 0 0;
    border-bottom: 1px solid #333;
  }

  .navigation-menu {
    position: relative;
    padding: 0 100px 0 160px;

    .account-btn,
    .logo-link {
      position: absolute;
      top: 0;
    }

    .logo-link {
      left: 0;
      margin: -10px 0 0 0;
    }

    .account-btn {
      background: none;
      border: none;
      right: 0;
      margin: 0;
    }
  }

  .corner-ribbon {
    width: 100px;
    background: #e43;
    position: absolute;
    top: 12px;
    left: -25px;
    text-align: center;
    line-height: 25px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .corner-ribbon.sticky {
    position: fixed;
  }
  .corner-ribbon.red {
    background: #e43;
  }

  .logo-link {
    display: block;
    overflow: hidden;
    width: 150px;
    height: 60px;
    position: relative;
    background: $white url('/img/kolumbus-logo.png') no-repeat 0 center / auto 40px;

    &.-akt {
      background-image: url('/img/akt-logo.png');
    }

    &.-brakar {
      &,
      &:after {
        background-image: url('/img/brakar.svg');
        background-size: 130px auto;
      }

      &:after,
      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        height: 30px;
      }

      &:before {
        background: $white none repeat 0 0;
        width: 100px;
        height: 30px;
        top: auto;
        bottom: 0;
      }

      &:after {
        background-repeat: no-repeat;
        background-size: auto 50px;
        background-position: -50px -30px;
        width: 90px;
        margin: -12px 0 0 0;
      }
    }

    &.-ostfold {
      background-image: url('/img/ostfold.png');
      background-size: 150px auto;
    }

    h1 {
      font-size: 1px;
      position: absolute;
      right: 100%;
      bottom: 100%;
    }
  }

  .menu-list {
    white-space: nowrap;
    > li {
      display: inline-block;
    }
  }

  .account-info-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    .cnt {
      padding: 0 20px 0 0;
      text-align: right;
      font-size: 60%;
      font-style: italic;
      @include opacity(0.7);
    }
  }

  .view-information-wrapper {
    position: relative;
    padding: 0 185px 0 0;

    .action-holder {
      position: absolute;
      top: 0;
      right: 0;

      &,
      .secondary-btn,
      .primary-btn {
        width: 175px;
      }

      .secondary-btn,
      .primary-btn {
        text-align: center;
        margin: 0 0 10px 0;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .main-version-information {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    > ul {
      display: block;
      max-width: 500px;
      margin: 0 auto;
      white-space: nowrap;
      text-align: center;
      font-size: 85%;

      > li {
        display: inline-block;
        @include opacity(0.9);
        .as-label {
          padding: 0 10px;
          @include opacity(0.5);
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-md-p) {
    .navigation-menu {
      padding: 0;
      .navigation-header {
        position: relative;
      }

      .menu-list {
        text-align: center;
      }

      .logo-link {
        position: relative;
        top: auto;
        left: auto;
      }

      .account-btn {
        margin-top: 15px;
      }
    }

    .view-information-wrapper {
      padding: 0;
      .action-holder {
        position: relative;
        top: auto;
        right: auto;
        text-align: center;

        &,
        .secondary-btn,
        .primary-btn {
          width: auto;
        }

        .secondary-btn,
        .primary-btn {
          margin: 10px 5px 0 5px;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    .menu-list {
      margin-top: -10px;
      .menu-btn {
        margin: 0 5px;
      }
    }
  }
}
