@import "../style/theme";
@import "../style/mixin";

.suggestion-wrapper {
  .suggestion-cnt {
    position: relative;

    &.-dropdown-menu {
      .suggestion-holder {
        visibility: hidden;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  .suggestion-widget {
    position: absolute;
    left: 1px;
    right: 1px;
    top: 100%;
    z-index: 120;

    .matched-item,
    .empty-list {
      display: block;
      padding: 5px 5px 5px 25px;
      background: #fff none no-repeat 3px center / auto 20px;
      color: $color-font;
    }

    .empty-list {
      font-style: italic;
    }

    .matched-item {
      font-weight: 500;
      border: 1px solid #ccc;
      margin: 0 0 -1px 0;
      position: relative;
      box-shadow: none;
      width: 100%;
      text-align: left;

      &:hover,
      &:focus,
      &.-focus {
        color: $blue;
        outline: none;
        z-index: 1;
        border-color: $blue;
      }

      &.-selected {
        color: $blue;
        background-image: url("/img/checked.svg");
      }
    }

    .loader-wrapper {
      border: 1px solid $color-border;
      background: $white none repeat 0 0;
    }
  }

  .suggestion-holder {
    > li {
      display: inline-block;
      margin: 0 4px 2px 0;
      .tag-btn {
        overflow: hidden;
        position: relative;
        border: 1px solid $color-border;
        border-radius: $border-round;
        background: $white none no-repeat 0 0;
        padding: 5px 25px 5px 10px;
      }

      .icon-btn {
        height: auto;
        position: absolute;
        right: 1px;
        top: 0;
        bottom: 0;
        background-size: 11px auto;
        @include opacity(0.4);

        &:hover,
        &:focus {
          @include opacity(1);
        }
      }
    }
  }
}
