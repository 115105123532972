@import './theme';
@import './mixin';

ul.table-list {
  display: table;
  width: 100%;

  &.-half-width {
    width: 50%;
  }
  
  &.-auto-width {
    width: auto;
  }

  &.-listing {
    > li {
      .column {
        padding-top: 5px;
        padding-bottom: 5px;
        border: 0 none;

        &:first-child {
          width: 130px;
          white-space: nowrap;
          padding-left: 0;
          @include opacity(.6);
        }
      }
    }    
  }

  > li {
    display: table-row;
    @include transition( all, .2s, ease-in-out);

    &.odd-row {
      background-color: #eee;
    }

    &.table-header {
      font-weight: 500;
      background: #333 none repeat 0 0;
      border-radius: $border-round;

      &, a {
        color: $white;
      }
    }

    .column {
      display: table-cell;
      padding: 10px;
      text-align: left;
      border-left: 1px solid #ddd;

      &.date, &.channel, &.payment, &.refund {
        text-align: center;
      }

      &.price {
        text-align: right;
      }

      &:first-child {
        border-left: 0 none;
      }

      &.-align-left {
        text-align: left;
      }

      &.-align-right {
        text-align: right;
      }

      &.-half {
        width: 50%;
      }

      &.-full {
        width: 100%;
      }
    }
  }
}

ul.table-list.home-table-list {
  margin: 20px 0;
  > li {

    &.table-header:hover {
      background-color: #333;       
    }
    
    &:hover {
      background-color: $beige;
    }

    .column {
      &:first-child {
        text-align: left;
      }

      &.-last {
        text-align: right;
      }
    }
  }
}

/*@media screen and (max-width: $breakpoint-md) {*/
@media screen and (max-width: $breakpoint-lg) {
  ul.table-list.home-table-list:not(.-break-to-single-column-when-md-p) {
    margin-top: 20px;
    display: block;
    
    &.-auto-width, &.-half-width {
      width: 100%;
    }

    &.-log-table {
      > li .column:before {
        min-width: 90px;
      }
    }
  
    > li {
      display: inline-block;
      width: 48%;
      margin: 0 1% 10px 1%;
      background: $white none repeat 0 0;
      border-radius: $border-round;
      box-shadow: 0 0 0 1px $color-border;

      &:hover, &.odd-row {
        background: $white none repeat 0 0;
      }

      &.table-header {
        display: none;          
      }

      .column {
        text-align: left;
        padding: 2px 5px;
        float: left;
        width: 50%;
        @include ellipsis();

        &:before {
          content: attr(aria-label) ': ';
          font-size: 90%;
          float: left;            
          min-width: 60px;
          @include opacity(.5);
          @include ellipsis();
        }

        &.-last {
          text-align: left;
        }

        .icon-btn {
          margin-bottom: -5px;

          &.-not-confirmed {
            padding-left: 0;
          }
        }
      }
    }
  }   
}

@media screen and (max-width: $breakpoint-md-p) {
  ul.table-list.home-table-list:not(.-break-to-single-column-when-md-p) {
    > li {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  } 

  ul.table-list.home-table-list.-break-to-single-column-when-md-p {
    margin-top: 10px;
    display: block;
    
    &.-auto-width, &.-half-width {
      width: 100%;
    }

    &.-log-table {
      > li .column:before {
        min-width: 90px;
      }
    }
  
    > li {
      display: inline-block;
      width: 100%;
      margin: 0 0 10px 0;
      background: $white none repeat 0 0;
      border-radius: $border-round;
      box-shadow: 0 0 0 1px $color-border;

      &:hover, &.odd-row {
        background: $white none repeat 0 0;
      }

      &.table-header {
        display: none;          
      }

      .column {
        text-align: left;
        padding: 2px 5px;
        float: left;
        width: 100%;
        @include ellipsis();

        &:before {
          content: attr(aria-label) ': ';
          font-size: 90%;
          float: left;            
          min-width: 60px;
          @include opacity(.5);
          @include ellipsis();
        }

        &.-last {
          text-align: left;
        }

        .icon-btn {
          margin-bottom: -5px;

          &.-not-confirmed {
            padding-left: 0;
          }
        }
      }
    }      
  }
}

@media screen and (max-width: $breakpoint-sm) {
  ul.table-list.home-table-list:not(.-break-to-single-column-when-md-p) {
    margin-top: 10px;
    > li {
      .column {
        width: 100%;
      }
    }
  }   
}