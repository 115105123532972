.sub-menu {
  background: lightgray;
  border-bottom: 1px solid gray;
  ul {
    display: flex;
    max-width: 1580px;
    margin: 0 auto;
    padding-left: 53px;

    li {
      margin-right: 3rem;
      line-height: 2rem;

      a.active {
        font-weight: 800;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}
