@charset "UTF-8";

@-webkit-keyframes current-price-glow {
  0% {
    background-color: $color-brand;
  }
  50% {
    background-color: $orange;
  }
  100% {
    background-color: $color-brand;
  }
}

@keyframes current-price-glow {
  0% {
    background-color: $color-brand;
  }
  50% {
    background-color: $orange;
  }
  100% {
    background-color: $color-brand;
  }
}

@-webkit-keyframes free-price-glow {
  0% {
    background-color: $green;
  }
  50% {
    background-color: $green-light;
  }
  100% {
    background-color: $green;
  }
}

@keyframes free-price-glow {
  0% {
    background-color: $green;
  }
  50% {
    background-color: $green-light;
  }
  100% {
    background-color: $green;
  }
}

/*****************************************************************************/
@-webkit-keyframes expandline {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes expandline {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

/*****************************************************************************/
@-webkit-keyframes collapseline {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

@keyframes collapseline {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

/*****************************************************************************/
@-webkit-keyframes opacityview {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes opacityview {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*****************************************************************************/
@-webkit-keyframes opacityhide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes opacityhide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*****************************************************************************/
@-webkit-keyframes setupinnerview {
  0% {
    opacity: 0;
    top: 1rem;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes setupinnerview {
  0% {
    opacity: 0;
    top: 1rem;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

/*****************************************************************************/
@-webkit-keyframes setupIntroductionBtn {
  0% {
    top: 200%;
  }
  100% {
    top: -100%;
  }
}

@keyframes setupIntroductionBtn {
  0% {
    top: 200%;
  }
  100% {
    top: -100%;
  }
}

/*****************************************************************************/
@-webkit-keyframes editing-movedown {
  0% {
    margin-top: -100%;
  }
  100% {
    margin-top: 30px;
  }
}

@keyframes editing-movedown {
  0% {
    margin-top: -100%;
  }
  100% {
    margin-top: 30px;
  }
}

/*****************************************************************************/
@-webkit-keyframes form-appending {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes form-appending {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
