.order-history-page {
  table {
    tbody tr:hover {
      background-color: lightyellow;
    }

    tr:nth-child(even) {
      background: #eee;
    }

    margin-top: 2rem;

    tr {
      cursor: pointer;
    }

    th {
      border-left: 1px solid #ddd;
      background-color: #333;
      color: white;
      padding: 10px 20px;
      text-align: left;
    }
    td {
      border-left: 1px solid #ddd;
      padding: 10px 20px;

      &:first-child {
        border-left: none;
      }

      &.action {
        width: 30px;
        padding: 0;
        text-align: center;
      }
    }
  }

  .rds-message--danger {
    margin-top: 2rem;
  }

  form {
    display: flex;
    width: 500px;

    div {
      flex-grow: 1;
    }
  }
}
