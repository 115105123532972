@import '../style/theme';
@import '../style/mixin';

.tablist-wrapper {
  .payment-wrapper {
    ul.table-list.-listing {
      > li {
        .column:first-child {
          min-width: 150px;
        }
      }
    }
  }

  .show-more-btn {
    margin: 10px 0 0 0;
  }
}