$max-width-small: 600px;
$max-width: 1580px;

$max-width-editing: $max-width-small;
$max-width-help-page: $max-width-small;
$max-width-graph-page: $max-width-small;
$max-width-send-ticket-page: $max-width-small;
/**
 * Base stuff
 */
$base-font-size: 16px;
$base-font-family: 'Roboto', 'DIN', sans-serif;
$base-font-size-sm: 14px;

/**
 * Font-sizes (in pixels). Refer to relevant sections for their implementations.
 */
$h1-size: 39px; // .alpha
$h2-size: 28px; // .beta
$h3-size: 20px; // .gamma
$h4-size: 18px; // .delta
$h5-size: 16px; // .epsilon
$h6-size: 16px; // .zeta

$h1-size-sm: 32px; // .alpha
$h2-size-sm: 24px; // .beta
$h3-size-sm: 18px; // .gamma
$h4-size-sm: 16px; // .delta
$h5-size-sm: 14px; // .epsilon
$h6-size-sm: 14px; // .zeta

/******* COLOR ********/
$beige: #f5f5dc;
$red: #e60000;
$sand: #e1d5af;
$green: #71a100; //#8ab023;
$green-light: #c9e09e;
$green-dark: #567a00;
$orange: #e19100;
$white: #ffffff;
$grey: #676767;
$grey-light: #cccccc;
$grey-dark: #333333;
$blue: #0075d2;
$purple: #9100c7;

/**
 * Brand stuff
 */
$color-brand: #e60000;
$color-secondary: #32374b;
$color-remark: #333941;
$color-warning: #ffc800;
$color-error: #a92424;
$color-success: $green;
$color-building: #ced3db;
$color-link: #1c85fc;

$color-background: #f5f5f5; //#d7d7d7;
$color-font: #2d2d2d;
$color-border: #999999;
$color-border-light: #eeeeee;
$color-focus: #0075d2;
$color-action: #89b339;
$color-profile: #006ab0;
$color-link: #0075d2;
$color-primary: $green;

$color-train: #610078; /*#3e3959;*/
$color-airportTrain: #32374b;
$color-metro: #ec700c;
$color-tram: #0b91ef;
$color-boat: #682c88;
$color-bus: #76a300;
$color-airportBus: #6e0a14;

$color-admin-user: #913fc4;
$color-normal-user: #ffda45;
$color-inactive-user: #444444;

$border-round: 2px;

$breakpoint-xs: 320px;
$breakpoint-sm: 480px;
$breakpoint-md-p: 768px;
$breakpoint-md: 980px;
$breakpoint-lg: 1024px;

$layout-breakpoint-xxs: $breakpoint-xs;
$layout-breakpoint-sm: $breakpoint-sm;
