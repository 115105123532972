.app-loading {
  display: flex;
  align-items: center;
  margin: 50px auto;
  justify-content: center;

  .spinner {
    width: 40px;
    height: 40px;
    border-width: 5px;
    margin: 0;
  }

  .text {
    font-size: 1.5rem;
    margin-left: 1rem;
    font-weight: 500;
  }
}
