.rds-pagination.hide-last-page-link {
  .rds-pagination-arrow--last{
    display: none;
  }
}

.rds-pagination {
  margin-top: 1rem;
  font-size: 1.125rem;
    line-height: 1.74rem;
}