@import '../common/style/mixin';

.search-wrapper {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  @include transition(all, 0.2s, ease-in-out);
}

.search-wrapper {
  .textfield {
    padding-right: 90px;
    padding-left: 10px;
  }

  .submit-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding-left: 30px;

    button {
      width: 60px;
      text-align: center;
      height: 100%;
    }

    .reset-button {
      position: absolute;
      left: 0;
      top: 50%;
      margin: -10px 0 0 0;
    }

    .icon-btn {
      background-size: auto 15px;
      width: 15px;
      height: 15px;
    }
  }
}
