@import '../common/style/mixin';

.home-wrapper {
	position: relative;

	.search-tool {
		position: relative;
		margin: 0 0 20px 0;

		&.-open-advance {
			.advance-search-wrapper {
				overflow: visible;
				max-height: 700px;
				@include opacity(1);
			}

			.search-wrapper {
				.advance-search-btn {
					&, &:after, &:before {
						@include opacity(1);
					}
				}
			}
		}

		.search-wrapper,
		.advance-search-wrapper{
			max-width: 600px;
			margin: 0 auto;			
			position: relative;
			@include transition( all, .2s, ease-in-out);
		}

		.advance-search-wrapper {
			overflow: hidden;
			max-height: 0;
			@include opacity(0);

			.calendar-cnt {
				input.textfield {
					margin: 0;
					width: 48%;
					margin: 0 0 0 2%;

					&.input-a {
						margin: 0 2% 0 0;
					}
				}
			}

			ul.input-holder {
				> li {
					display: inline-block;

					.input-radio + label, .input-checkbox + label {
						white-space: nowrap;
						font-weight: normal;
					}
				}
			}

			.search-order-status-wrapper ul.input-holder > li {
				min-width: 240px;
			}
		}

		.search-wrapper {
			.textfield {
				padding-right: 90px;
				padding-left: 65px;
			}

			.submit-wrapper {
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				padding-left: 30px;

				button {
					width: 60px;
					text-align: center;
					height: 100%;
				}

				.reset-button {
					position: absolute;
					left: 0;
					top: 50%;
					margin: -10px 0 0 0;
				}

				.icon-btn {
          background-size: auto 15px;					
          width: 15px;
          height: 15px;

          &:focus {
            outline: none;
          }
				}
			}

			.advance-search-btn {
				position: absolute;
				left: 1px;
				top: 1px;
				bottom: 1px;				
				border-width: 0 1px 0 0;
				padding-top: 10px;
				padding-left: 3px;
				padding-right: 3px;
				background-color: #eee;
				font-size: 80%;
				@include opacity(.5);

        &:focus {
          outline: none;
        }

				&:hover {
					border-color: $color-border;
					&, &:after, &:before {
						@include opacity(1);
					}
				}

				&:after, &:before {
					content: '';
					border-style: solid;
					border-width: 8px;
					border-color: transparent transparent $color-border transparent;
					position: absolute;
					left: 50%;
					bottom: 100%;
					z-index: 2;
					margin: 0 0 0 -8px;
					@include opacity(0);
				}

				&:after {
					border-color: transparent transparent #eee transparent;
					margin-bottom: -1px;
				}
			}

			.appinstances-search-widget {
				width: 100%;
				top: 100%;

				.empty-text {
					padding: 10px;
				}

				.appinstance-list {
					a.link {
						display: block;
						text-decoration: none;
						color: $color-font;
						border: 1px solid transparent;
						box-shadow: 0 0 1px $color-border;

						&:hover, &:focus {
							color: $blue;
							border-color: $blue;
						}

						> div {
							display: table-cell;
							vertical-align: middle;
							padding: 5px 10px;
							white-space: nowrap;

							&:first-child {
								width: 100%;
							}

							&.instance-created-date {
								font-style: italic;
								@include opacity(.7);
								font-size: 80%;
							}
						}
					}
				}
			}
		}
	}

  .home-content {
  	min-height: 300px;
  }
  
	@media screen and (max-width: $breakpoint-sm) {
		.search-tool {
			.search-wrapper {
				.appinstances-search-widget {
					.appinstance-list {
						a.link {
							> div {
								&.instance-created-date {
									display: none;
								}
							}
						}
					}
				}
			}
			
			&.-open-advance {
				.advance-search-wrapper {
					max-height: 900px;

					.last-shortcut-link {
						display: none;
					}
				}
			}
		}
	}
}
