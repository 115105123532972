.order-page {
  dl {
    display: flex;
    flex-flow: row wrap;
    line-height: 1.5rem;

    dd {
      margin-left: 20px;
      flex-basis: 130px;
      font-weight: 500;
    }

    dt {
      flex-basis: calc(100% - 130px - 20px);
    }
  }

  table {
    tr:nth-child(even) {
      background: #eee;
    }

    margin-top: 2rem;

    tr {
      cursor: pointer;
    }

    th {
      border-left: 1px solid #ddd;
      background-color: #333;
      color: white;
      padding: 10px 20px;
      text-align: left;
    }
    td {
      border-left: 1px solid #ddd;
      padding: 10px 20px;

      &:first-child {
        border-left: none;
      }
    }
  }

  .actions {
    text-overflow: unset;
    padding: 0;
    text-align: center;

    &>div {
      display: flex;
      justify-content: center;
    }

    button {
      background: #F8F8F8;
      border: 1px solid #DDDDE1;
      border-radius: 2px;
      color: #262626;
      width: 40px;
      height: 32px;
      overflow: hidden;
      margin: 10px 5px;
    }
  }
}
