@import '../common/style/mixin';
@import '../common/style/theme';

.graph-wrapper {
	position: relative;
	padding: 0 0 200px 0;

	.graph-tool {
		max-width: $max-width-graph-page;
		margin: 0 auto;		
	}

	.graph-tool-wrapper {
		position: relative;
		padding: 0;

		.calendar-cnt {
			input {
				width: 49%;
				margin: 0 0 0 1%;
				&.input-a {
					margin: 0 1% 0 0;					
				}
			}
		}
	}

	.graph-time {
		font-style: italic;
		font-size: 85%;
		@include opacity(.8);
	}

	.graph-view {
		padding: 40px 0 0 0;
		max-width: $breakpoint-lg;
		margin: 0 auto;
	}

	.graph-chart-wrapper {
		position: relative;

		h1, h2, h3 {
			position: relative;
			margin-top: 0;
			.graph-time {
				font-size: 50%;
				position: absolute;
				right: 0;
				top: 0;
				font-weight: 500;
			}
		}

		.graph-chart-content {
			padding-top: 30px;
		}

		.recharts-legend-wrapper {
			padding-left: 50px;
		}
	}

	.graph-chart-view-wrapper {
		position: relative;
		display: block;

		legend {
			position: absolute;
			left: 0;
			top: 0;
			width: 1px;
			overflow: hidden;
			@include opacity(.1);
		}

		ul > li {
			display: inline-block;
			white-space: nowrap;
			position: relative;

			.input-radio {
				position: absolute;
				label {
					max-width: none;
				}
			}
		}
	}

}
