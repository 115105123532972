@import './theme';

h1, .h1, {
  &, &.-force {
    font-size: $h1-size;
    line-height: $h1-size * 1.2;
  }
}

h2, .h2, {
  font-size: $h2-size;
  line-height: $h2-size * 1.2;
}

h3, .h3, {
  font-size: $h3-size;
  line-height: $h3-size * 1.2;
}

h4, .h4, {
  font-size: $h4-size;
  line-height: $h4-size * 1.2;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  font-weight: 700;
  margin-bottom: 15px;

  &.no-margin, &.body-header {
    margin-bottom: 0;
  }
}

body, .body-text, .body-header {
  font-weight: normal;
  position: relative;
}

body, .body-text, .body-header, textarea, input, h1, h2, h3, h4, h5, p, button, a {
  font-family: $base-font-family;
}

body, .body-text, textarea, input, button, label, legend, select, option, .action-btn, .primary-btn, .secondary-btn, h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  color: $color-font;
}

body, .body-text, textarea, input, button, label, legend, select, option, .action-btn, .primary-btn, .secondary-btn {
  font-size: $base-font-size;
  line-height: $base-font-size * 1.2;
}

@media screen and (max-width: $layout-breakpoint-sm) { 
  h1, .h1, {
    font-size: $h1-size-sm;
    line-height: $h1-size-sm * 1.2;
  }

  h2, .h2, {
    font-size: $h2-size-sm;
    line-height: $h2-size-sm * 1.2;
  }

  h3, .h3, {
    font-size: $h3-size-sm;
    line-height: $h3-size-sm * 1.2;
  }

  h4, .h4, {
    font-size: $h4-size-sm;
    line-height: $h4-size-sm * 1.2;
  }

  h1, h2, .h1, .h2 {
    margin-bottom: 15px;
  }

  h3, h4, .h3, .h4 {
    margin-bottom: 8px;
  }  

  body, .body-text, textarea, input, button, label, legend, select, option, .action-btn, .primary-btn, .secondary-btn {
    font-size: $base-font-size-sm;
    line-height: $base-font-size-sm * 1.2;
  }
}