@import "./theme";
@import "./mixin";

.aria-visible {
  position: absolute;
  left: 0;
  top: 0;
  @include opacity(0.1);
  padding: 0;
  margin: 0;
  color: transparent;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.empty-text {
  font-style: italic;
}

.relative {
  position: relative;
}
.static {
  position: static;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.pre-wrap {
  white-space: pre-wrap;
}
.no-wrap {
  white-space: nowrap;
}
.normal-wrap {
  white-space: normal;
}
.underline {
  text-decoration: underline;
}
.bold {
  font-weight: 700;
}
.italic {
  font-style: italic;
}
.block {
  display: block;
}
.hide {
  display: none;
}
.right-space {
  margin-right: 0.5rem;
}
.left-space {
  margin-left: 0.5rem;
}
.small-text {
  font-size: 90%;
}
.shy {
  @include opacity(0.7);
}
