@import "./theme";
@import "./mixin";

/******************************************************************************
  button
******************************************************************************/
.action-btn,
.primary-btn,
.secondary-btn {
  position: relative;
  display: inline-block;
  border-radius: $border-round;
  cursor: pointer;
  margin: 0;
  padding: 8px 15px;
  box-shadow: none;
  border: 0 none;
  color: $color-border;
  text-decoration: none;

  &,
  &.-active-bounce {
    top: 0;
    &:active {
      top: 2px;
    }
  }

  &.-loading {
    color: $white;
    &:after {
      width: 20px;
      height: 20px;
      margin: -10px 0 0 -10px;
      border-width: 3px;
    }
  }

  &.-hide {
    display: none;
  }

  &.-block {
    display: block;
  }

  &:hover {
    border-color: $blue;
  }

  &.-disabled {
    cursor: default;
    &,
    &:focus,
    &:hover,
    &:active,
    &:visited {
      background-color: #e3e6ec !important;
      color: #757575 !important;
      top: 0 !important;
    }
  }

  &.-as-link {
    border-color: transparent !important;
    text-decoration: underline;
  }

  &.-small {
    padding: 4px 8px;
    &.-uncheck,
    &.-check {
      padding-left: 25px;
      &:before {
        margin-left: 3px;
        background-size: auto 25px;
      }
    }
  }

  &.-large {
    padding: 0 20px;
    font-size: 22px;
    font-weight: 500;
    line-height: 57px;
  }
}

.action-btn {
  color: $white;
  background: $green none repeat 0 0;
  border: 1px solid $color-action;

  &.-arrow-right:after {
    border-color: transparent transparent transparent $color-action;
  }
  &.-arrow-left:after {
    border-color: transparent $color-action transparent transparent;
  }
}

.primary-btn {
  border: 1px solid $color-primary;
  background: $color-primary none repeat 0 0;
  color: $white;

  &.-arrow-right:after {
    border-color: transparent transparent transparent $color-primary;
  }
  &.-arrow-left:after {
    border-color: transparent $color-primary transparent transparent;
  }
}

.secondary-btn {
  border: 1px solid $color-border;
  background: $white none no-repeat 0 0;
  color: $color-font;

  &.-blue {
    color: $blue;
    border-color: $blue;
    &.-fill {
      background-color: $blue;
      color: $white;
    }
  }

  &.-green {
    color: $green;
    border-color: $green;
    &.-fill {
      background-color: $green;
      color: $white;
    }
  }

  &.-green-dark {
    color: $green-dark;
    border-color: $green-dark;
    &.-fill {
      background-color: $green-dark;
      color: $white;
    }
  }

  &.-brand-blue {
    color: $color-secondary;
    border-color: $color-secondary;
    &.-fill {
      background-color: $color-secondary;
      color: $white;
    }
  }

  &.-danger {
    color: $red;
    border-color: $red;

    &.-fill {
      background-color: $red;
      color: $white;
    }
  }
  &.-arrow-right:before {
    border-color: transparent transparent transparent $color-border;
  }
  &.-arrow-left:before {
    border-color: transparent $color-border transparent transparent;
  }

  &.-arrow-right:after {
    border-color: transparent transparent transparent $white;
  }
  &.-arrow-left:after {
    border-color: transparent $white transparent transparent;
  }
}

a.action-btn,
a.primary-btn {
  &,
  &:focus,
  &:active {
    color: $white;
  }
}

button.action-btn,
button.primary-btn,
button.secondary-btn {
  &.-block {
    width: 100%;
  }
}

/******************************************************************************
  menu-btn
******************************************************************************/
.menu-btn {
  display: inline-block;
  padding: 10px 0;
  margin: 0 15px;
  position: relative;
  text-align: center;
  min-width: 60px;
  top: 0;

  &:active {
    top: 2px;
  }

  &,
  &:before {
    @include transition(all, 0.2s, ease-in-out);
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background: transparent none repeat 0 0;
  }

  &:hover,
  &.-active {
    font-weight: 700;
    font-color: $color-secondary;
    &:before {
      background-color: $color-secondary;
    }
  }
}

/******************************************************************************
  icon-btn
******************************************************************************/
a.icon-bt,
button.icon-btn {
  &:active {
    top: 2px;
  }
}

.icon-btn {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: transparent none no-repeat center center / 20px auto;
  text-align: left;
  border: none;

  &.-expander {
    &:before {
      content: "\276F";
      position: absolute;
      left: 50%;
      top: 0;
      margin: 0 0 0 -5px;
      transform: rotateZ(90deg);
      @include transition(all, 0.3s, linear);
      color: $red;
    }

    &.-expanded:before {
      transform: rotateZ(-90deg);
      color: inherit;
    }
  }

  &.-bin {
    background-image: url("/img/bin.svg");
  }

  &.-cross,
  &.-close {
    background-image: url("/img/close.svg");
    &.-red {
      background-image: url("/img/close-r.svg");
    }
  }

  &.-android {
    background-image: url("/img/android.svg");
  }
  &.-ios {
    background-image: url("/img/ios.svg");
  }

  &.-visa {
    width: 30px;
    background-size: 30px auto;
    background-image: url("/img/visa.svg");
  }
  &.-mastercard {
    width: 30px;
    background-size: 30px auto;
    background-image: url("/img/mastercard.svg");
  }

  &.-strex,
  &.-vipps {
    width: 40px;
    text-align: center;
    &:before {
      content: "vipps";
      font-size: 14px;
      font-weight: 500;
    }
  }
  &.-strex {
    &:before {
      content: "strex";
    }
  }

  &.-refund {
    background-image: url("/img/refund.svg");
    &.-blue {
      background-image: url("/img/refund-b.svg");
    }
  }

  &.-change {
    background-image: url("/img/change.svg");
    &.-blue {
      background-image: url("/img/change-b.svg");
    }
  }

  &.-confirmed {
    background-image: url("/img/checked-g.svg");
  }

  &.-include-text {
    width: auto;
    min-width: 25px;
    padding-left: 25px;
    background-position: left center;
  }

  &.-in-right,
  &.-in-left {
    width: auto;
    white-space: nowrap;
  }

  &.-in-right {
    padding-right: 37px;
    padding-left: 5px;
    background-position: 100% center;
  }

  &.-in-left {
    padding-left: 37px;
    padding-right: 5px;
    background-position: 0px center;
  }
}

/******************************************************************************
  sort-btn
******************************************************************************/
.sort-btn {
  display: block;
  font-weight: 500;
  background: none;
  border: none;
  color: white;
  text-decoration: underline;
  position: relative;
  padding-right: 10px;
  white-space: nowrap;
  width: 100%;

  &:before,
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: -2px;
    border-width: 6px 5px;
    border-style: solid;
    border-color: transparent;
  }

  &:before {
    border-bottom-color: $color-border;
  }
  &:after {
    border-top-color: $color-border;
    margin-top: 14px;
  }

  &.-desc:after {
    border-top-color: $blue;
  }
  &.-incr:before {
    border-bottom-color: $blue;
  }
}
