@import '../common/style/mixin';
@import '../common/style/theme';

_:-ms-fullscreen, :root .info-page-wrapper {
	max-width: $max-width-small;
	.info-list {
		&, .info-item {
			display: block;
		}

		.info-item {
			margin: 0 0 15px 0;
		}
	}	

	.suggestion-search-wrapper .suggestion-search-header .cnt {
		.suggestion-field-wrapper {
			margin-bottom: -30px;
			input:focus {
				background-position: -75px center;
			}
		}
	}
}

.info-page-wrapper {
	position: relative;
	max-width: $breakpoint-md;
	margin: 0 auto;

	.info-list {
	  min-height: 100%;
	  display: flex;
	  flex-wrap: wrap;
	  flex-direction: row;

		.info-item {
			position: relative;
			margin: 0 1% 15px 1%;

		  display: flex; 
		  flex-basis: 48%;
		  justify-content: top;
		  flex-direction: column;

			&:before, &:after {
				content: '';
				position: absolute;
				left: 15px;
				width: 105px;
				bottom: 16px;
				top: 57px;
				background: #ccc none no-repeat 0 0;
				display: none;
			}

			.text {
				position: relative;
				z-index: 2;

				&:before, &:after {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 95px;
					overflow: hidden;
					display: none;
					font-size: 80%;
					margin: 20px 0 0 5px;
					text-align: center;
					background: transparent none no-repeat center center;
					white-space: nowrap;
					@include opacity(.7);
				}
			}

			&.-delete-payment,
			&.-cancel-order,
			&.-block-email,
			&.-delete-phone,
			&.-send-receipt,
			&.-send-pickup-code,
			&.-refund {
				&:before {
					display: block;
				}
				.text {
					padding-left: 115px;
					min-height: 60px;

					&:before {
						display: block;
						content: 'Refunder';
						border: 1px solid $color-border;
						border-radius: $border-round;
						background: $blue none no-repeat 0 0;
						color: $white;
						padding: 2px 0;
					}
				}
			}

			&.-refund {
				.text {
					&:after {
						margin-top: 60px;
						display: block;
						height: 20px;
			      background-image: url('/img/refund-b.svg');
					}
				}
			}

			&.-send-pickup-code .text:before {
				content: 'Send hentekode';
			}

			&.-send-receipt .text:before {
				content: 'Send kvittering';
			}
			&.-delete-phone .text:before {
				content: 'Slett telefonnr.';
			}
			&.-block-email .text:before {
				content: 'Sperre epost';
			}
			&.-cancel-order .text:before {
				content: 'Kanseller';
			}

			&.-delete-payment .text:before {
				content: 'Slett';
				background: transparent none no-repeat 0 0;
				border: 0 none;
				color: $blue;
				text-decoration: underline;
			}
		}
	}

	.paper {
		h2, h3 {
			padding-top: 0;
			margin-top: 0;
		}
	}

	.suggestion-search-wrapper {
		position: relative;
		.suggestion-widget {
			display: none;
		}

		.suggestion-search-header {
			display: table;
			width: 100%;
			white-space: nowrap;
			.cnt {
				display: table-cell;
				width: 100%;
				&:first-child {
					width: auto;
					padding: 0 5px 0 0;
				}
			}
		}

		label {
			position: absolute;
			width: 1px;
			font-size: 1px;
			color: transparent;
			overflow: hidden;
			@include opacity(.1);
		}

		.suggestion-field-wrapper {
			input {
				@include opacity(.4);
				background: $white url('/img/search.svg') no-repeat 5px center / auto 20px;
				padding-left: 30px;
				padding-right: 0;
				width: 0;
				border-color: transparent;
				@include transition( all, .2s, ease-in-out);

				&:hover {
					border-color: $color-border;
					@include opacity(1);
				}

				&:focus {
					width: 100%;
					border-color: $color-border;
					@include opacity(1);
				}
			}

			&.-has-search-text input {
				width: 100%;
				border-color: $color-border;
				@include opacity(1);
			}
		}
	}

	@media screen and (max-width: $breakpoint-md-p) {
		.info-list {
			&, .info-item {
				display: block;
			}
		}	
	}

	@media screen and (max-width: $breakpoint-sm) {
		.info-list {
			display: block;
			.info-item {
				display: block;
				margin:  10px 0;

				&:before, &:after {
					top: 48px;
				}
			}
		}
	}
}
