@import '../common/style/mixin';

.export-wrapper {
	position: relative;
	max-width: $max-width-help-page;
	margin: 0 auto;

	.export-content {
		padding: 15px 0 0 0;
	}

	.calendar-cnt {
		input.textfield {
			margin: 0;
			width: 48%;
			margin: 0 0 0 2%;

			&.input-a {
				margin: 0 2% 0 0;
			}
		}
	}
}
