.help-general {
  max-width: 600px;
  margin: 0 auto;

  input {
    background-image: url("/img/search.svg");
    background-repeat: no-repeat;
    background-position: right 5px center;
    background-size: 20px;
    height: 2rem;
    width: 400px;
    padding-right: 30px;
  }

  .error-code-card {
    border-radius: 2px;
    box-shadow: 0 0 0 1px #999999;
    margin-top: 30px;
    position: relative;
    background: white;
    padding: 20px;

    .iterator {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 100%;
      text-align: center;
      font-size: 80%;
      background: #f5f5f5 none repeat 0 0;
      opacity: 0.7;
    }

    .block {
      display: flex;
      margin-bottom: 1rem;

      .label {
        flex: 0 0 100px;
        color: #2d2d2d;
        opacity: 0.7;

        &::after {
          content: ':';
        }
      }

      .content-language {
        font-style: italic;
        font-size: 0.75rem;

        &::after {
          content: ':';
        }
      }

      .content-text {
        margin-bottom: 1rem;
      }
    }
  }
}
