.help-issue {
  max-width: 600px;
  margin: 0 auto;

  .date {
    font-style: italic;
    margin-bottom: 1rem;
  }

  p:first-child {
    margin-top: 0;
  }

  p {
    padding-bottom: 0;
  }

  .issue-title,
  .comment-title {
    font-weight: 500;
  }
}
