@import '../common/style/mixin';

.view-order-wrapper {
	position: relative;

	.view-order-content {
		clear: both;
		max-width: 1020px;
		&:after {
			content: '';
			clear: both;
			display: block;
			width: 100%;
		}

		.cnt {
			float: left;
			width: 50%;
		}
	}

	@media screen and (max-width: $breakpoint-md-p) {
		.view-order-content {
			&, .cnt {
				display: block;
				width: 100%;
				float: none;
			}
		}
	}
}
