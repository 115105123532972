@import '../style/theme';
@import '../style/mixin';

.editing-wrapper {
  padding: 0;
  position: absolute;
  z-index: 100;

  &, &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:before {
    content: '';
    position: fixed;
    background: rgba(0,0,0,.6) none repeat 0 0;
  }

  .editing-frame {
    max-width: $max-width-editing;
    margin: -100% auto 0 auto;
    position: relative;
    box-shadow: none;

    @include animation( editing-movedown, .2s, ease, 0s );
    @include animation-forwards();

    &.-success {
      .editor-wrapper {
        overflow: hidden;
        max-height: 0;
        @include opacity(0);
      }

      .success-wrapper {
        max-height: 200px;
        @include opacity(1);
      }
    }

    .editor-wrapper, .success-wrapper {
      @include transition( all, .2s, linear );
    }

    .editor-wrapper {
      max-height: 1000px;
      @include opacity(1);
    }

    .success-wrapper {
      text-align: center;
      max-height: 0;
      overflow: hidden;
      @include opacity(0);

      .success-message {
        margin: 10px 0 20px 0;
      }
    }
  }

  h1, h2, h3 {
    text-align: center;
    margin-top: 10px;
  }

  .information-wrapper {
    margin: 0 -15px 20px -15px;
    padding: 15px;
    background: $color-background none repeat 0 0;
    border-top: 1px solid $color-border;
    border-bottom: 1px solid $color-border;

    .cnt {
      display: table-cell;
      width: 50%;
    }

    .icon-btn {
      &.-mastercard, &.-visa {
        margin-bottom: -4px;
      }
    }
  }

  .editing-closer {
    top: 10px !important;
    height: 15px;
    width: 15px;
    position: absolute;
    right: 10px;
    top: 10px;
    background-size: auto 15px;
  }

  .form-action-holder {
    text-align: center;
    margin: 0 0 10px 0;
    .primary-btn, .secondary-btn {
      width: 120px;
      margin: 0 10px;
    }
  }

  .refund-textfield-wrapper {
    position: relative;
    .calculated {
      position: absolute;
      top: 0;
      right: 0;
      font-style: italic;
      font-size: 80%;
      z-index: 1;
    }
  }

  .send-pickup-code-textfield-wrapper {
    position: relative;
    padding-left: 100px;
    .input-content {
      clear: none;
      &:first-child {
        float: left;
        width: 90px;
        margin: 0 0 0 -100px;
      }

      .select-box {
        padding-right: 27px;
      }
    }
  }

  .move-ticket-field-wrapper {
    position: relative;
    .input-content.-invalid:first-child {
      + .suggestion-wrapper {
        .suggestion-widget {
          margin-top: -45px;
        }
      }
    }

    .suggestion-wrapper,
    .suggestion-cnt {
      position: static;
    }

    .suggestion-wrapper {
      padding: 0;
      margin: 0;
      label, input {
        display: none;
      }
      .suggestion-widget {
        margin-top: -20px;
        left: 0;
        right: 0;
      }
    }
  }

  @media screen and (max-width: $breakpoint-md-p) {
    .information-wrapper {
      .cnt {
        display: block;
        width: auto;
      }
    }
  }
}
